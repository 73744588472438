.footer{
  color: var(--text-primary-white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  margin-top: auto;
}

.footer .center{
  user-select: none;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  font-size: 14px;
}

.footer .copyright{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.footer .right{
  height: 50px;
  width: 50px;
  border-radius: 50px;
  background-color: var(--text-transparent-black);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  position: fixed;
  bottom: 24px;
  right: 24px;
}

.footer .right:hover{
  background-color: var(--text-transparent-white);
  color: var(--background-colour-black);
}

@media(max-width:600px){
  .footer{
    margin-right: auto;
    padding: 24px 16px ;
  }

  .footer .right{
    right: 16px;
  }
  
}
