#Pagenotfound{
    color: var(--text-disabled-white);
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2%;
    font-size: 18px;
}

#Pagenotfound p{
    width: 80vw;
}

#Pagenotfound span{
    cursor: pointer;
    font-family: 'SFProDisplayRegular';
}