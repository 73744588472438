@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


:root{
  --background-colour-black:#131313;

  --text-primary-white:#FFFFFF;
  --text-secondary-white:hsl(0, 0%, 80%);
  --text-disabled-white:hsl(0, 0%, 65%);
  --text-transparent-white:hsla(0, 0%, 100%, 0.3);
  --text-transparent-black:hsla(0, 0%, 0%, 0.3);

  --neutral-300:#d4d4d4;
  --neutral-300-hover:#c6c6c6;
  --neutral-500:#737373;
  --neutral-Dark:#373737;
}

body {
  margin: 0;
  background-color: var(--background-colour-black);
  padding: 0;
  box-sizing: border-box;
  font-family: 'SFProDisplayLight', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'SFProDisplayLight', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@font-face {
  font-family: 'SFProDisplayBlack';
  src: url('./Font/SF Pro Display/SF-Pro-Display-Black.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplayBold';
  src: url('./Font/SF Pro Display/SF-Pro-Display-Bold.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplayMedium';
  src: url('./Font/SF Pro Display/SF-Pro-Display-Medium.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplayRegular';
  src: url('./Font/SF Pro Display/SF-Pro-Display-Regular.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplayUltralight';
  src: url('./Font/SF Pro Display/SF-Pro-Display-Ultralight.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'SFProDisplayLight';
  src: url('./Font/SF Pro Display/SF-Pro-Display-Light.woff') format('woff');
  font-display: swap;
}

body::-webkit-scrollbar{
  width: 4px;
  height: 4px;
}

body::-webkit-scrollbar-track{
  background-color: var(--background-colour-black);
}

body::-webkit-scrollbar-thumb{
  background-color: var(--text-disabled-white);
  border-radius: 100px;
  height: 10vh;
  width:10vw;
}

body::-webkit-scrollbar-thumb:hover, body::-webkit-scrollbar-thumb:active{
  background-color: var(--text-primary-white);
}
