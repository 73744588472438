.navbar {
    padding: 24px;
}

.navbar-web{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-colour-black);
    color: var(--text-secondary-white);
}

.navbar .left, .navbar .right{
    width: 32vw;
    display: flex;
    gap: 24px;
    font-size: 16px;
}

.navbar .right{
    padding-right: 0px;
    justify-content: end;

}
.navbar .left{
    padding-left:0px;
    justify-content: start;
}

.right .icon{
    font-size: 20px;
}

.navbar .nav-link-center{
    font-family: 'SFProDisplayMedium';
    color: var(--text-primary-white);
    text-decoration: none;
    font-size: 18px;
}

.nav-link {
    color: var(--text-disabled-white);
    text-decoration: none;
    font-family: 'SFProDisplayLight';
}

.nav-link:hover, .nav-link.active {
    color: var(--text-primary-white);
}

.navbar-mobile{
    display: none;
}



@media(max-width:860px){
    .navbar {
        padding: 24px 16px;
    }
    
    .navbar-web{
        display: none;
    }

    .nav-mobile{
        position: fixed;
        top: -100vh;
        left: 0;
        height: 100vh;
        width: 100vw;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--background-colour-black);
        z-index: 2;
        transition: 1s;
        transform: translateY(-100vh);
        padding: 0px 16px;
    }

    .nav-close-btn {
        position: absolute;
        top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100vw;
    }

    .mobile-menu-header{
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

    .mobile-menu-header{
        color: var(--text-disabled-white);
    }

    .nav-link-center{
        color: var(--text-primary-white); 
    }

    .nav-link-mobile{
        font-size: 48px;
        width: 100vw;
        display: flex;
        text-align: left;
        color: var(--text-disabled-white);
        text-decoration: none;
    }

    .nav-link-mobile:hover, .nav-bar-logo:hover, .mobile-menu-header{
        color: var(--text-primary-white); 
    }

    .cta-mobile{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .responsive_nav {
        transform:translateY(100vh);
        overflow: hidden;
    }

    .nav-bar-logo{
        width: 40px;
        height: 40px;
        color: var(--text-disabled-white);
        cursor: pointer;
        padding-right: 32px;
    }

    .nav-bar-menu{
        width: 40px;
        height: 40px;
        cursor: pointer;
    }

}